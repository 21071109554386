<template>
  <div class="welfare-container">
    <div v-if="isPage('Welfare')">
      <Carousel />
      <Main />
    </div>
    <router-view v-if="isPage('WelfareDetail')" />
  </div>
</template>

<script>
import Carousel from './Carousel'
import Main from './Main'
import { getWelfareList } from '@/api/client/welfare'
import resStatus from '@/constants/resStatus'

export default {
  components: {
    Carousel,
    Main
  },
  methods: {
    isPage (routeName) {
      return this.$route.name === routeName
    },
    updateWelfareList () {
      getWelfareList(this.listQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.welfareData = res
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.welfare-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 30px;
  & > div {
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .welfare-container {
    width: 100%;
    padding: 0;
    & > div {
      margin-bottom: 20px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .welfare-container {
    width: 100%;
    padding: 0;
    & > div {
      margin-bottom: 20px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .welfare-container {
    width: 98%;
    padding: 20px 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .welfare-container {
    width: 97%;
    padding: 20px 0;
  }
}
@media (min-width: 1200px) {}
</style>
