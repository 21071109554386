<template>
  <div>
    <div
      class="main-container">
      <div class="type-container">
        <ul>
          <li>专属福利活動</li>
          <!-- <li
            @click="handleSelType('all')"
            :class="isSelType('all')">
            全部活动
          </li> -->
          <li
            @click="handleSelType('ongoing')"
            :class="isSelType('ongoing')">
            进行中
          </li>
          <li
            @click="handleSelType('preparing')"
            :class="isSelType('preparing')">
            准备中
          </li>
          <li
            @click="handleSelType('ended')"
            :class="isSelType('ended')">
            已结束
          </li>
        </ul>
      </div>
      <div class="article-container">
        <ul>
          <li
            v-for="(item, idx) in articles"
            :key="'article-' + idx"
            class="article-item">
            <router-link
              :target="isMobile ? '_self' : '_blank'"
              :to="{name: 'WelfareDetail', query: {id: item.id}}"
              @click.native="sendVisit(item.id)"
              class="item-container">
              <div
                class="logo-container"
                :style="{'background-image': `url(${item.photo})`}">
                <!-- <img :src="" /> -->
              </div>
              <div class="info-container">
                <h3 class="title">{{ item.title }}</h3>
                <p class="content" v-html="item.content"></p>
                <p class="time">活动时间：<span>{{ getTime(item.start_at) }}</span> 到 <span>{{ getTime(item.end_at) }}</span></p>
              </div>
            </router-link>
            </li>
          </ul>
          <Pagination
            :curPage="curPage"
            :pageSize="pageSize"
            :totalPages="totalPages"
            @handleChangePage="handleChangePage" />
      </div>
    </div>
  </div>
</template>

<script>
import { isBeforeDate, isBetweenTwoDate, isAfterDate, dateFormat } from '@/utils/time'
import { ignoreHTMLTag } from '@/utils/string'
import { getWelfareList } from '@/api/client/welfare'
import resStatus from '@/constants/resStatus'
import Pagination from './Pagination'
import { visit } from '@/api/client/visit'
import { contentLimit } from '@/utils/contentLimit'
import { isMobile } from '@/utils/device'
import moment from 'moment'

export default {
  components: {
    Pagination
  },
  computed: {
    content: {
      get () {
        return this.getPageData('content')
      }
    },
    curPage: {
      get () {
        return this.getPageData('curPage')
      }
    },
    pageSize: {
      get () {
        return this.getPageData('pageSize')
      }
    },
    totalPages: {
      get () {
        return this.getPageData('totalPages')
      }
    },
    articles: {
      get () {
        let res = []
        switch (this.selType) {
          case 'preparing':
            res = this.paginationDatas.preparing.result.data.filter((item) => {
              let start = moment(item.start_at).format()
              let end = moment(item.end_at).format()
              return isBeforeDate(start, end)
            })
            break
          case 'ongoing':
            res = this.paginationDatas.ongoing.result.data.filter((item) => {
              let start = moment(item.start_at).format()
              let end = moment(item.end_at).format()
              return isBetweenTwoDate(start, end)
            })
            break
          case 'ended':
            res = this.paginationDatas.ended.result.data.filter((item) => {
              let start = moment(item.start_at).format()
              let end = moment(item.end_at).format()
              return isAfterDate(start, end)
            })
            break
          default:
            res = this.paginationDatas.ongoing.result
        }
        return contentLimit(res)
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  data () {
    return {
      selType: 'ongoing',
      listQuery: {
        page: 1,
        pageSize: 10
      },
      welfareData: [],
      visitQuery: {
        to: 'benefit',
        uuid: '',
        id: ''
      },
      paginationDatas: {
        preparing: {
          query: {
            state: 'preparing',
            page: 1,
            pageSize: 10
          },
          result: {
            data: [],
            totalPages: 0
          }
        },
        ongoing: {
          query: {
            state: 'ongoing',
            page: 1,
            pageSize: 10
          },
          result: {
            data: [],
            totalPages: 0
          }
        },
        ended: {
          query: {
            state: 'ended',
            page: 1,
            pageSize: 10
          },
          result: {
            data: [],
            totalPages: 0
          }
        }
      }
    }
  },
  methods: {
    sendVisit (id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getPageData (dataName) {
      var tmpArr = []
      switch (this.selType) {
        case 'ongoing' :
          tmpArr = this.paginationDatas.ongoing
          break
        case 'preparing' :
          tmpArr = this.paginationDatas.preparing
          break
        case 'ended' :
          tmpArr = this.paginationDatas.ended
          break
      }
      switch (dataName) {
        case 'content':
          return tmpArr.result.data
        case 'curPage':
          return tmpArr.query.page
        case 'pageSize':
          return tmpArr.query.pageSize
        case 'totalPages':
          return tmpArr.result.totalPages
        default:
          break
      }
    },
    handleSelType (type) {
      this.selType = type
      this.updateResult()
    },
    isSelType (type) {
      if (this.selType === type) {
        return 'active'
      }
    },
    // getContent (content) {
      // const tmp = ignoreHTMLTag(content)
      // if (tmp.length >= 85) {
      //   return tmp.slice(0, 87) + '...'
      // }
    //   return content
    // },
    getTime (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD')
    },
    updateWelfareList () {
      getWelfareList(this.listQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.welfareData = res
          }
        })
    },
    emptyResult () {
      this.paginationDatas.ongoing.result = {
        data: [],
        totalPages: 0
      }
      this.paginationDatas.preparing.result = {
        data: [],
        totalPages: 0
      }
      this.paginationDatas.ended.result = {
        data: [],
        totalPages: 0
      }
    },
    updateResult () {
      this.emptyResult()
      switch (this.selType) {
        case 'ongoing' :
          getWelfareList(this.paginationDatas.ongoing.query)
            .then(res => {
              if (res.status === resStatus.OK) {
                this.paginationDatas.ongoing.result = res
              }
            })
          break
        case 'preparing' :
          getWelfareList(this.paginationDatas.preparing.query)
            .then(res => {
              if (res.status === resStatus.OK) {
                this.paginationDatas.preparing.result = res
              }
            })
          break
        case 'ended' :
          getWelfareList(this.paginationDatas.ended.query)
            .then(res => {
              if (res.status === resStatus.OK) {
                this.paginationDatas.ended.result = res
              }
            })
          break
      }
    },
    handleChangePage (page) {
      switch (this.selType) {
        case 'ongoing' :
          this.paginationDatas.ongoing.query.page = page
          break
        case 'preparing' :
          this.paginationDatas.preparing.query.page = page
          break
        case 'ended' :
          this.paginationDatas.ended.query.page = page
          break
      }
      this.updateResult()
    }
  },
  created () {
    this.updateResult()
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  justify-content: space-between;
  padding: 40px 50px;
  background-color: #FFF;
  margin-top: 20px;
  border-radius: 5px;
  // gap: 40px;
  .type-container {
    width: 100%;
    margin-right: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    ul {
      width: inherit;
      border-radius: 5px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      li {
        width: inherit;
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        line-height: 60px;
        padding: 0 32px;
        border-bottom: 1px solid #E0E0E0;
        color: #4F4F4F;
        // display: flex;
        // align-items: center;
        &:not(:first-child) {
          cursor: pointer;
        }
        &:first-child {
          font-size: 21px;
          border-radius: 5px 5px 0px 0px;
          color: #FFFFFF;
          background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
        }
        &:last-child {
          border-bottom: none;
          border-radius: 0 0 5px 5px;
        }
      }
      .active {
        color: #005FDE;
      }
    }
  }
  .article-container {
    flex-grow: 99;
    ul {
      .article-item {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        &:first-child {
          .item-container {
            padding: 0 0 20px;
          }
        }
        .item-container {
          display: flex;
          justify-content: flex-start;
          // gap: 20px;
          padding: 20px 0;
          .logo-container {
            width: 135px;
            height: 100px;
            min-width: 135px;
            min-height: 100px;
            margin-right: 20px;
            border-radius: 5px;
            background-size: cover;
            background-position: center;
          }
          .info-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            // gap: 10px;
            .title {
              font-size: 18px;
              color: #333333;
              margin-bottom: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
            }
            .content {
              font-size: 15px;
              line-height: 21px;
              color: #000000;
              margin-bottom: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .time {
              color: #4F4F4F;
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid #E0E0E0;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .main-container {
    flex-direction: column;
    padding: 15px;
    margin-top: 0;
    .type-container {
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        box-shadow: none;
        padding: 5px 0;
        li:first-child {
          width: 100%;
          height: fit-content;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          background: transparent;
          color: #4f4f4f;
          margin: 0;
        }
        li {
          width: 6em;
          height: 30px;
          font-size: 13px;
          line-height: 30px;
          padding: 0;
          text-align: center;
          border-bottom: none;
          margin: 15px 0;
          &:last-child {border-radius: 20px;}
        }
        .active {
          color: #FFF;
          font-weight: 500;
          background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
          border-radius: 20px;
        }
      }
    }
    .article-container {
      ul {
        .article-item:first-child {
          .item-container {
            padding: 0 0 15px;
          }
        }
        .article-item {
          .item-container {
            // gap: 20px;
            padding: 15px 0;
            .logo-container {
              width: 100px;
              height: 70px;
              min-width: 100px;
              min-height: 70px;
              border-radius: 3px;
              margin-right: 13px;
              overflow: hidden;
            }
            .info-container {
              // gap: 10px;
              .title {
                font-size: 15px;
                font-weight: 500;
                line-height: 21px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: unset;
                white-space: unset;
              }
              .content {
                display: none;
              }
              .time {
                font-size: 12px;
                color: #828282;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container {
    flex-direction: column;
    padding: 15px;
    margin-top: 0;
    .type-container {
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        box-shadow: none;
        padding: 5px 0;
        li:first-child {
          width: 100%;
          height: fit-content;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          background: transparent;
          color: #4f4f4f;
          margin: 0;
        }
        li {
          width: 6em;
          height: 30px;
          font-size: 13px;
          line-height: 30px;
          padding: 0;
          text-align: center;
          border-bottom: none;
          margin: 15px 0;
          &:last-child {border-radius: 20px;}
        }
        .active {
          color: #FFF;
          font-weight: 500;
          background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
          border-radius: 20px;
        }
      }
    }
    .article-container {
      ul {
        .article-item:first-child {
          .item-container {
            padding: 0 0 15px;
          }
        }
        .article-item {
          .item-container {
            // gap: 20px;
            padding: 15px 0;
            .logo-container {
              width: 100px;
              height: 70px;
              min-width: 100px;
              min-height: 70px;
              margin-right: 13px;
              border-radius: 3px;
              overflow: hidden;
            }
            .info-container {
              // gap: 10px;
              .title {
                font-size: 15px;
                font-weight: 500;
                line-height: 21px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: unset;
                white-space: unset;
              }
              .content {
                display: none;
              }
              .time {
                font-size: 12px;
                color: #828282;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container {
    padding: 30px 20px;
    margin-top: 15px;
    .type-container {
      width: 160px;
      margin-right: 30px;
      ul {
        li:first-child {
          font-size: 19px;
        }
        li {
          height: 50px;
          line-height: 50px;
          font-size: 18px;
          padding: 0 20px;
        }
      }
    }
    .article-container {
      max-width: calc(98vw - 40px - 30px - 160px);
      ul {
        width: inherit;
        .article-item:first-child {
          .item-container {
            padding: 0 0 20px;
          }
        }
        .article-item {
          .item-container {
            justify-content: unset;
            // gap: 20px;
            padding: 20px 0;
            .logo-container {
              width: 135px;
              height: 100px;
              min-width: 135px;
              min-height: 100px;
            }
            .info-container {
              // gap: 10px;
              max-width: calc(100% - 135px - 20px);
              .title {
                font-size: 18px;
                font-weight: 500;
              }
              .content {
                font-size: 15px;
              }
              .time {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container {
    padding: 40px;
    .type-container {
      width: 250px;
      ul {
        li:first-child {
          font-size: 21px;
        }
        li {
          height: 60px;
          font-size: 20px;
          padding: 0 32px;
        }
      }
    }
    .article-container {
      ul {
        .article-item:first-child {
          .item-container {
            padding: 0 0 20px;
          }
        }
        .article-item {
          .item-container {
            // gap: 20px;
            padding: 20px 0;
            .logo-container {
              width: 135px;
              height: 100px;
              min-width: 135px;
              min-height: 100px;
            }
            .info-container {
              // gap: 10px;
              .title {
                font-size: 18px;
                font-weight: 500;
              }
              .content {
                font-size: 15px;
              }
              .time {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .main-container {
    .type-container {
      width: 290px;
      ul {
        li:first-child {
          font-size: 21px;
        }
        li {
          height: 60px;
          font-size: 20px;
          padding: 0 32px;
        }
      }
    }
    .article-container {
      ul {
        .article-item:first-child {
          .item-container {
            padding: 0 0 20px;
          }
        }
        .article-item {
          .item-container {
            // gap: 20px;
            padding: 20px 0;
            .logo-container {
              width: 135px;
              height: 100px;
              min-width: 135px;
              min-height: 100px;
            }
            .info-container {
              // gap: 10px;
              .title {
                font-size: 18px;
                font-weight: 500;
              }
              .content {
                font-size: 15px;
              }
              .time {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
