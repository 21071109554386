import request from '@/utils/axios'

// 取得 新聞/玩法規則/贏錢技巧列表
export function getWelfareList (params) {
  return request({
    url: '/api/info/benefit',
    method: 'get',
    params: params
  })
}

export function getWelfareBanner () {
  return request({
    url: 'api/info/benefit/banners',
    method: 'get'
  })
}

export function getWelfare (id) {
  return request({
    url: `/api/info/benefit/${id}`,
    method: 'get'
  })
}
