<template>
  <div class="carousel-container">
    <div class="welfare-carousel">
      <Carousel
        v-if="banners.length > 0"
        :banners="banners"
        :title="showTitle"
        :controller="!isMobile"
        :slideTo="slideOn"
        :slidePause="slidePause"
        @slideIdx="slideIdx"
        @visitWelfare="visitWelfare" />
    </div>
    <div
      v-if="!isMobile"
      class="welfare-info"
      @mouseover="pauseSlider(true)"
      @mouseleave="pauseSlider(false)">
      <ul class="info-list">
        <li
          v-for="(item, idx) in conceptData"
          :key="'welfare-concept-' + idx"
          @mouseover="slideIdx(idx)"
          class="info-item">
          <router-link
            target="_blank"
            v-if="item.title.length > 0"
            :class="slideOn == idx ? 'slide-on' : ''"
            :to="{ name: 'WelfareDetail', query: { id: item.id }}"
            @click.native="sendVisit(item.id)">
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Carousel from './Carousel'
import resStatus from '@/constants/resStatus'
import { getWelfareBanner } from '@/api/client/welfare'
import { visit } from '@/api/client/visit'
import { isMobile } from '@/utils/device'

export default {
  components: {
    Carousel
  },
  data () {
    return {
      welfareBanner: [],
      visitQuery: {
        to: 'benefit',
        uuid: '',
        id: ''
      },
      slideOn: 0,
      slidePause: false
    }
  },
  computed: {
    conceptData: {
      get () {
        const tmpData = this.welfareBanner
        if (!this.isMobile) {
          while (tmpData.length < 6) {
            tmpData.push({
              title: '',
              content: '',
              startData: null,
              endDate: null
            })
          }
        }
        return tmpData
      }
    },
    banners: {
      get () {
        const tmpArr = []
        this.welfareBanner.forEach(ele => {
          if (ele.photo_bg) {
            tmpArr.push(ele)
          }
        })
        return tmpArr
      }
    },
    showTitle: {
      get () {
        return window.innerWidth < 992
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    updateWelfareBanner () {
      getWelfareBanner()
        .then(res => {
          if (res.status === resStatus.OK) {
            this.welfareBanner = res.data
          }
        })
    },
    sendVisit (id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    visitWelfare (id) {
      this.sendVisit(id)
      this.$router.push({ name: 'WelfareDetail', query: { id: id }})
    },
    slideIdx (idx) {
      if(idx < this.banners.length) {
        this.slideOn = idx
      }
    },
    pauseSlider (boo) {
        this.slidePause = boo || false
    }
  },
  created () {
    this.updateWelfareBanner()
  }
}
</script>

<style lang="scss" scoped>
.carousel-container {
  display: flex;
  justify-content: center;
  border: 0px solid;
  border-radius: 5px;
  .welfare-carousel{
    background-color: #C4C4C4;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    & > div{
      width: 100%;
      height: inherit;
    }
  }
  .welfare-info {
    display: flex;
    flex-direction: column;
    flex-grow: 99;
    border-radius: 0px 5px 5px 0px;
    background: linear-gradient(180deg, #001339 0%, #001339 100%);
    .info-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .info-item {
        min-height: fit-content;
        overflow: hidden;
        &:first-child {border-radius: 0 5px 0 0;}
        &:last-child {border-radius: 0 0 5px 0;}
        a {
          display: block;
          color: inherit;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          font-weight: 500;
          text-align: left;
          color: #FFFFFF;
          &:hover {
            background-color: #2F80ED;
          }
        }
        .slide-on {background-color: #2F80ED;}
        &:not(:last-child) {
          border-bottom: 1px solid #294889;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .carousel-container {
    position: relative;
    height: 49.261vw;
    max-height: 49.261vw;
    .welfare-carousel {
      width: 100%;
      min-width: 100%;
      height: inherit;
      border-radius: 0;
    }
    .welfare-info {
      display: none;
      .info-list {
        .info-item {
          font-size: 18px;
          padding: 19px 22px;
          min-height: 65px;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-container {
    position: relative;
    height: 49.261vw;
    max-height: 49.261vw;
    .welfare-carousel {
      width: 100%;
      min-width: 100%;
      height: inherit;
      border-radius: 0;
    }
    .welfare-info {
      display: none;
      .info-list {
        .info-item {
          font-size: 18px;
          padding: 19px 22px;
          min-height: 65px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .carousel-container {
    position: relative;
    height: 49.261vw;
    min-height: 49.261vw;
    .welfare-carousel {
      width: 100%;
      min-width: 100%;
      height: inherit;
      border-radius: 5px;
    }
    .welfare-info {
      display: none;
      .info-list {
        .info-item {
          font-size: 18px;
          padding: 19px 22px;
          min-height: 65px;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-container {
    height: 330px;
    min-height: 330px;
    .welfare-carousel {
      width: 669.9px;
      min-width: 669.9px;
      height: inherit;
    }
    .welfare-info {
      max-width: calc(97vw - 669.9px);
      .info-list {
        .info-item {
          min-height: 55px;
          & a {
            font-size: 18px;
            padding: 0 8px 0 18px;
            line-height: 55px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .carousel-container {
    height: 400px;
    min-height: 400px;
    .welfare-carousel {
      width: 812px;
      min-width: 812px;
      height: inherit;
      border-radius: 5px 0px 0px 5px;
    }
    .welfare-info {
      max-width: 385px;
      .info-list {
        .info-item {
          min-height: 66.8px;
          & a {
            font-size: 18px;
            padding: 0 15px 0 25px;
            line-height: 66px;
          }
          
        }
      }
    }
  }
}
</style>
