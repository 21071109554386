<template>
  <div>
    <b-carousel
      ref="welfareCarousel"
      v-model="slide"
      :interval="slideInterval"
      :controls="controller"
      indicators
      background="#ababab"
      class="carousel-container"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <b-carousel-slide
        v-for="(banner, idx) in banners"
        :key="idx+banner.photo_bg"
      >
        <template #img>
          <div
            class="w-100 image-block"
            :style="{'background-image': `url(${$common.resSrc(banner.photo_bg)})`}"
            @click="visitWelfare(banner.id)"
          >
            <div
              v-if="title"
              class="title"
            >{{banner.title}}</div>
          </div>
        </template>
      </b-carousel-slide>
      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!-- <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100 slide-item"
            width="1024"
            height="480"
            src="https://picsum.photos/1024/480/?image=55"
            alt="image slot"
          >
        </template>
      </b-carousel-slide> -->
    </b-carousel>
  </div>
</template>

<script>

export default {
  name: 'mainCarousel',
  props: {
    banners: {
      type: Array,
      required: true
    },
    title: {
      type: Boolean,
      default: false
    },
    controller: {
      type: Boolean
    },
    slideTo: {
      type: Number,
      default: 0
    },
    slidePause: {
      type: Boolean
    }
  },
  data () {
    return {
      slide: this.slideTo,
      sliding: null,
      slideInterval: 4000
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
      this.$emit('slideIdx', slide)
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    visitWelfare (id) {
      this.$emit('visitWelfare', id)
    }
  },
  watch: {
    slideTo (cur) {
      this.$refs.welfareCarousel.setSlide(cur)
    },
    slidePause (cur) {
      if(cur) {
        this.slideInterval = 0
      } else {
        this.slideInterval = 4000
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-container {
  width: 100%;
  height: inherit;
  .image-block {
    position: relative;
    width: 100%;
    height: inherit;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    .title {
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}
@media (max-width: 575.98px) {
  .carousel-container {
    .image-block {
      .title {
        max-width: 14em;
        bottom: 10px;
        line-height: 25px;
        left: 15px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-container {
    .image-block {
      .title {
        max-width: 15em;
        bottom: 15px;
        line-height: 25px;
        left: 15px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .carousel-container {
    .image-block {
      .title {
        max-width: 76%;
        bottom: 42px;
        font-size: 20px;
        line-height: 25px;
        left: 12%;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>

<style lang="scss">
.carousel-inner {
  height: inherit;
  .carousel-item {
    height: inherit;
  }
}
.carousel-indicators {
  bottom: 10px;
  & li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
@media (max-width: 575.98px) {
  .carousel-indicators {
    justify-content: flex-end;
    margin-right: 20px;
    margin-left: 20px;
    bottom: 15px;
    & li {
      width: 8px;
      height: 4px;
      border-radius: 10px;
      opacity: 1;
      background-color: #828282;
      background-clip: unset;
      border-top: 0;
      border-bottom: 0;
    }
    .active {
      width: 15px;
      height: 4px;
      background-color: #f2c94c;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-indicators {
    justify-content: flex-end;
    margin-right: 20px;
    margin-left: 20px;
    bottom: 20px;
    & li {
      width: 8px;
      height: 4px;
      border-radius: 10px;
      opacity: 1;
      background-color: #828282;
      background-clip: unset;
      border-top: 0;
      border-bottom: 0;
    }
    .active {
      width: 15px;
      height: 4px;
      background-color: #f2c94c;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .carousel-control-prev,
  .carousel-control-next {
    height: 80%;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    margin-top: 50%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
}
</style>
